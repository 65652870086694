<template>
  <div>
    <Header></Header>

    <h1 class="text-justify text-center">Google Docs</h1>
    <SearchBar
      @searched="userSearch"
      place-holder="Search for user"
      class="searchbar"
    ></SearchBar>
    <GoogleDocLinkItem
      v-for="user in filteredUserList"
      :key="user.id"
      :user-detail="user"
    />
  </div>
</template>

<script>
import Header from "@/components/Header";
import axios from "axios";
import store from "@/store";
import GoogleDocLinkItem from "@/components/GoogleDocLinkItem";
import SearchBar from "@/components/SearchBar";
export default {
  name: "GoogleDocs",
  components: { SearchBar, GoogleDocLinkItem, Header },
  data() {
    return {
      userList: [],
      searchQuery: "",
    };
  },
  methods: {
    userSearch(data) {
      this.searchQuery = data;
    },
  },
  computed: {
    filteredUserList() {
      if (this.searchQuery) {
        return this.userList.filter((item) => {
          return this.searchQuery
            .toLowerCase()
            .split(" ")
            .every((v) => item.email.toLowerCase().includes(v));
        });
      } else {
        return this.userList;
      }
    },
  },
  mounted() {
    var config = {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    };
    axios
      .post("https://Pysberduck.pythonanywhere.com/api/allusers", {}, config)
      .then(
        (res) => {
          if (res.data["status"] == 200) {
            for (let i = res.data.data.length - 1; i >= 0; i--) {
              this.userList.push(res.data.data[i]);
            }
          } else swal("Some error occured");
        },
        (err) => {
          swal("Some error occured");
        }
      );
    store.state.loading = false;
  },
};
</script>

<style scoped>
.searchbar {
  margin-bottom: 10px;
}
</style>
