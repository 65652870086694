<template>
  <div class="border  container">
    <div class="form-group form-container">
      <h5>{{ userDetail.email }}</h5>
      <input
        name="doc link"
        type="text"
        class="form form-control "
        v-model="docLink"
      />

      <button class="btn btn-danger" @click="updateDocLink">Update</button>
    </div>
    <div>
      <h6>
        <i>Current doc link</i>: <strong>{{ savedDockLink }}</strong>
      </h6>
    </div>
  </div>
</template>

<script>
import db from "@/firebase";
import SearchBar from "@/components/SearchBar";

export default {
  name: "GoogleDocLinkItem",
  components: { SearchBar },
  props: ["userDetail"],
  data() {
    return {
      docLink: "",
      savedDockLink: ""
    };
  },
  methods: {
    updateDocLink() {
      if (this.validateUrl(this.docLink)) {
        db.collection("google-doc-links")
          .doc(this.userDetail.user_id)
          .set({
            link: this.docLink
          })
          .then(res => {
            this.savedDockLink = this.docLink;
          });
      } else {
        swal("Please Enter a valid url", "like: https://www.google.com");
      }
    },
    validateUrl(url) {
      return /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(
        url
      );
    }
  },
  async mounted() {
    await db
      .collection("google-doc-links")
      .doc(this.userDetail.user_id)
      .get()
      .then(doc => {
        if (doc.exists) this.savedDockLink = doc.data().link;
      });
  }
};
</script>

<style scoped>
.form-container {
  display: flex;
}

h5 {
  width: 30%;
  height: 10px;
}

.container {
  /*display: flex;*/
  padding: 20px;
}

.form {
  margin-left: 20px;
  max-width: 50%;
  margin-right: 30px;
}

button {
  width: 20%;
}

h6 {
  font-size: 16px;
  /*position: absolute;*/
  opacity: 0.6;
  font-family: "Londrina Solid", cursive;
  top: 50px;
}
</style>
